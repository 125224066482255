<template>
<div>
    <div class="login-div " style="background-size:contain">
        <div class="row">
            <div class="col-lg-8 col-12"></div>

            <div class="col-lg-4 col-12 login-container text-center">
                <img src="../../assets/images/logo.png" loading="lazy" height="90" class="auth-img" @click="sendToMarket">
                <div class="form-div">
                    <form action id="login-for" class="form">
                        <div class="alert alert-success" role="alert" v-if="transferActive && transferDetails.type !== '' && transferDetails.iu == 1">
  <h5 class="alert-heading">Almost Done!</h5>
  <p v-if=" transferDetails.type !== 'fund'">Login to claim your investment</p>
  <p v-else>Login to see the funds transfered to you</p>
</div>

                        <h4 class="mb-4 head-font">{{tran.welcome_back}}</h4>

                        <h6 class="mb-4">{{tran.login}}</h6>

                        <!-- <p class="color-red pb-4 fw-600" v-if="authenticateError">{{tran.please_login}}</p> -->

                        <div class="form-group">
                            <label class="form-label" @click.prevent="focusOnInput($event)" for="first">{{tran.userEmail}}</label>
                            <input v-model="formdata.email"    name="email" class="form-input" type="email" @keydown.enter.prevent="checkEnterButton">
                        </div>

                            <div class="form-group mt-4">
                                <label class="form-label" for="last" @click.prevent="focusOnInput($event)">{{tran.password}}</label>

                                <div class="eye-div">
                                    <input v-model="formdata.password"
                                          class="form-input"
                                          name="password"
                                         :type="hideInput"
                                         @keyup="changeInputType"
                                         @keydown.enter.prevent="checkEnterButton">
                                    <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <button type="button" class="submit-btn mt-2" :class="{'fade-elem': loader}"
                                 @click.prevent="login">

                  {{ loader ? '' : tran.login }}

                  <span class="loader mt-1" v-if="loader">
                  </span>
                </button>
                            </div>

                            <div style="padding-top:30px">

                                <span class="mt-4"> {{tran.forgotPassword}}</span>
                                <span class="underline" @click="takeToForgotPassword">{{tran.clickHere}}</span>

                            </div>

                            <h6 class="mt-60 fw-600 underline" @click="sendToSignUp" style="font-size:19px">{{tran.signup}}</h6>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import trans from "../../../translations";
import {userNotification} from "../dashboard/mixins/notifyUser.js";
import {
    mapState
} from "vuex";


export default {

    mixins:[userNotification],
    data() {
        return {
            tran: {},
            hideEye: false,
            showEye: true,
            hideInput: "password"
        };
    },
    computed: {
        ...mapState({
            formdata: state => state.auth.loginData,
            loader: state => state.auth.loader,
            authenticateError: state => state.auth.authenticateError,
             token: state => state.auth.token,
                transferActive: state => state.auth.transferActive,
                   transferDetails: state => state.auth.transferDetails,
        })
    },
    methods: {
  sendToMarket(){
            this.$router.push('/market')
        },

        login() {
            this.$store.dispatch("auth/loginUser");
        },

        checkEnterButton() {
            if (event.keyCode === 13) {
                this.login();
            }
        },

        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },

        showInputText() {
            this.hideInput = "text";
            this.showEye = false;
            this.hideEye = true;
        },

        hideInputText() {
            this.hideInput = "password";
            this.showEye = true;
            this.hideEye = false;
        },

        takeToForgotPassword() {
            this.$router.push('/forgot/password')
        },

        sendToSignUp() {

            this.$router.push('/signup')
        },
        changeInputType(){
             this.hideInput = 'password'
        }
    },

    activated() {

        if (this.$route.query.pg) {

            localStorage.setItem("userQuery", JSON.stringify(this.$route.query));

        }

    },

    mounted() {

        if (this.$route.query.pg) {

            localStorage.setItem("userQuery", JSON.stringify(this.$route.query));

        }
        this.tran = trans;
 this.$store.commit('auth/clearLoginData')

      this.$store.commit('auth/stopLoader')
        
//Check if user is logged in, if logged in redirect back to market
if(this.token !== null){

    this.$router.push('/market')
}
    },

    beforeDestroy(){

          this.$store.commit('auth/clearLoginData')
    }
};
</script>

<style>
</style>
