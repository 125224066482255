<template>
    <Login/>
</template>

<script>
import Login from '../../components/auth/Login'
export default {

    components: {
        Login
    }
}
</script>

<style>

</style>
